.divider {
    width: 100%;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: 0;
}

.dividerTop {
    width: 100%;
    height: 1px;
    background-color: black;
}

.image01 {
    width: auto;
    height: 100%;
    overflow: hidden;
}

.image02 {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.image03 {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.container {
    position: relative; /* This is necessary for positioning the pseudo-element */
    overflow: hidden; /* Ensures the pseudo-element won't exceed the container's bounds */
    color: #fff;
    width: 99%;
}

.container::before {
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Arial', sans-serif; /* Use the font that best matches your design */
    height: 100%;
    width: auto;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('/public/images/role_selection_background.jpg');
    filter: blur(1px); /* Adjust the pixel value as needed */
    z-index: -1; /* Ensures the pseudo-element is behind the content */
}