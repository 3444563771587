@font-face {
  font-family: "DIN Alternate bold";
  src: local("DINAlternateBold"),
  url("./fonts/DINAlternateBold.otf") format("truetype");
}

@font-face {
  font-family: "DIN Alternate light";
  src: local("DINAlternateLight"),
  url("./fonts/DINAlternateLight.ttf") format("truetype");
}

@font-face {
  font-family: "DIN Alternate regular";
  src: local("DINAlternateRegular"),
  url("./fonts/DINAlternateRegular.otf") format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "DIN Alternate regular", sans-serif;
}

body, html, #root {
  height: 100%;
}

.din-light-alternate {

}

.din-regular-alternate {
  font-family: 'D-DIN regular-alternate', sans-serif;
}

.container {
  margin-left: 1%;
}

.content-container {
  height: 100%;
  width: 98%;
  margin-left: 10px;
}

.content-container h3 {
  padding: 0;
  margin: 0;
}