.container {
    /* Use the relative path from the public folder for background */
    background-image: url('/public/images/role_selection_background.jpg'); /* Adjust the path as needed */
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    color: #fff;
    font-family: 'Arial', sans-serif; /* Use the font that best matches your design */
    position: relative; /* Needed for absolute positioning of pseudo-elements */
    width: 99%
}

/* Create a before pseudo-element for white lines */
.line {
    content: '';
    position: absolute;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 2px; /* Height of the white lines */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, #FFF 50%, rgba(255,255,255,0) 100%);
    transform: translateY(-50%); /* Center it vertically */
}
.verticalLine {
    content: '';
    position: absolute;
    top: 0;
    left: 100%; /* Center the line horizontally */
    height: 100%; /* Full height of the container */
    width: 2px; /* Width of the white lines, representing the thickness */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #FFF 50%, rgba(255,255,255,0) 100%);
    transform: translateX(-50%); /* Center it horizontally */
}

.textContainer {
    margin-left: auto;
    border-radius: 5px; /* Optional: if you want rounded corners */
    position: relative;
    text-align: right; /* Align text to the right */
    padding-right: 2%;
    align-items: flex-end;
    align-content: flex-end;
}

.textContainer h1,
.textContainer h2,
.textContainer p {
    margin: 0 0 20px; /* Consistent margin for all text elements */
}

.textContainer h1 {
    font-size: 24px; /* Larger font size for h1 */
    font-weight: bold; /* Bold font weight for h1 */
}

.textContainer h2 {
    font-size: 22px; /* Slightly smaller font size for h2 */
    font-weight: normal; /* Normal font weight for h2 */
}

.textContainer p {
    font-size: 18px; /* Adjust as needed */
    margin-bottom: 30px; /* More space before the links */
}

.link {
    padding: 10px 0px 10px 20px; /* Padding for the links */
    color: #fff;
    text-transform: uppercase; /* Uppercase text */
    text-decoration: none;
    font-weight: bold;
    border-radius: 2px; /* Rounded corners for the links */
    transition: background-color 0.3s ease; /* Transition for hover effect */
    display: block; /* This will make each link take up a full line */
    margin-bottom: 10px; /* Add some margin between the links if needed */
    margin-right: 0;
}

.link:hover {
    background-color: rgba(26, 26, 26, 0.6); /* Darker background on hover */
    text-decoration: none; /* No underline on hover */
}
