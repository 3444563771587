footer {
    text-align: center;
    margin-top: 1%;
    width: 98%;
    margin-left: 1%;
}
.footerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 2px solid black;
    margin: 0!important;
    width: 100% !important;
}

.footerLinks {
    list-style: none;
    padding: 0;
}
.footerLinks li {
    margin: 5px 0;
}

.footerLinks a {
    color: black;
    text-decoration: none;
}