.navbar {
    width: 100%;
    padding-right: 3px;
}

.navbar * {
    text-decoration: none;
    color: black;
}

.navLink {
    text-decoration: none;
    margin-bottom: 10px;
    display: block;
    font-family: 'DIN Alternate regular';
    font-size: 20px;
}

.boldNavLink {
    font-weight: bold;
}

.navDivider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-bottom: 10px;
}
