.navbar {
    width: 100%;
}

.navbar * {
    text-decoration: none;
    color: black;
}

.navLink {
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 4px;
    display: block;
}

.boldNavLink {
    font-weight: bold;
}

.navDivider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-bottom: 3px;
}
