.header {
    box-shadow: none !important;
    border-bottom: 2px solid black;
    width: 98% !important;
    margin-left: 1%!important;
    margin-bottom: 14px!important;
}

.headerTitle {
    color: black;
    font-size: 28px;
    margin-bottom: 14px!important;
    margin-top: 14px!important;
    font-family: "DIN Alternate bold", sans-serif;
    border: 0;
    background: none;
}
